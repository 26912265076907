<template>
  <v-app style="opacity: 0.9">
    <notifications group="foo" />
    <router-view style=""></router-view>
  </v-app>
</template>

<script>
export default {};
</script>
